/* Combined Row Styles */
.combinedRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  background: transparent;
}

/* Logo */
.logo img {
  height: 140px;
}























/* Social Icons */


/* Custom Navbar Style */
.customNavbar {
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1; /* Ensure it stays above the video */
  height: 25vh;
}






















.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  border-bottom: 2px solid #ccc;
  padding-bottom: 0;
}

.topInfoLeft {
  display: flex;
  align-items: center;
  flex-wrap: nowrap; /* Prevents wrapping */
  white-space: nowrap; /* Prevents text from breaking onto new lines */
}

.separator {
  margin: 0 8px; /* Slightly smaller margin to save space */
  color: white;
}

.icon,
.emailIcon {
  margin-right: 5px;
  color: white;
}

.infoText {
  color: white;
  text-decoration: none;
}

.socialIconsRight {
  display: flex;
  gap: 15px;
}

.socialIcon {
  font-size: 18px;
  color: white;
  cursor: pointer;
  transition: color 0.3s;
}

.socialIcon:hover {
  color: white;
}

/* Responsive styles for screens below 990px */
@media (max-width: 990px) {
  .infoContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .topInfoLeft,
  .socialIconsRight {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  /* Reduce font size and icon size for smaller screens */
  .infoText, .icon, .emailIcon {
    font-size: 0.9em; /* Adjust font size to fit better */
  }

  .socialIcon {
    font-size: 16px; /* Adjust icon size */
  }
}

/* Additional fine-tuning for extra small screens */
@media (max-width: 600px) {
  .infoText, .icon, .emailIcon {
    font-size: 0.8em; /* Further reduce font size */
  }

  .separator {
    margin: 0 5px; /* Narrower separator margin */
  }

  .socialIcon {
    font-size: 14px; /* Smaller icons */
  }
}

@media (max-width: 990px) {  
.logo img {
  display: none;
}
}























/* Main Navbar Styles */
.navLinksContainer {
  padding: 0;
  margin-top: -60px; /* Reduced margin from top */
  margin-left: 20px;
  width: 100%;
}

.navLinks {
  list-style-type: none;
  padding: 0;
  margin: -20px;
  display: flex;
  justify-content: center;
}

.navLink {
  margin: 0 10px;
  text-decoration: none;
  color: white;
  padding: 0.5rem 0;
}

.phoneButton {
  margin-left: 20px;
  padding: 6px 20px;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid black;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.logolesswidth990 img {
  height: 75px;
}
.logolesswidth780 img {
  height: 75px;
}
@media (min-width: 780px) {
  .logolesswidth780 img {
display: none;
  }
}

/* Responsive Adjustments */

/* Style adjustments below 900px */
@media (max-width: 900px) {
  

  .navLink, .phoneButton {
    font-size: 0.9em; /* Slightly smaller font size for mobile */
  }

 

  .navLinksContainer,
  .logolesswidth990 img {
    padding: 0 25px; 
  }

}

/* Hide navigation links below 780px */
@media (max-width: 780px) {
  .navLinksContainer {
    display: none;
  }
}

@media (min-width: 990px) {
  /* Hide logo image on larger screens */
  .logolesswidth990 img {
    display: none;
  }
}


@media (max-width: 780px) {
  /* Hide logo image on larger screens */
  .logolesswidth990 img {
    display: none;
  }
}
















/* mobile navbar */
.navContainer {
  display: flex;
  justify-content: space-between; /* Positions logo on the left and button on the right */
  align-items: center;
  width: 100%; /* Ensures the container spans the full width */
}
.menuButton{
background-color: transparent;
color: white;
border: none;
font-size: 30px;
}
@media (min-width: 780px){
  .navContainer {
    display: none;
  }
  .menuButton{
display: none;
  } 
}

 











/* modal navbar mobile */
/* Hide the nav links and show hamburger icon below 780px */
@media (max-width: 780px) {
  .navLinksContainer {
    display: none; /* Hide links by default */
  }

  /* Hamburger Icon styles */
  .hamburgerIcon {
    display: block; /* Ensure the hamburger icon is displayed */
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
  }

  /* Hamburger icon bars */
  .bar {
    width: 30px;
    height: 3px;
    background-color: #fff;
    margin: 6px 0;
  }

  .navLinksContainer.menuOpen {
    display: block; /* Show links when menu is toggled */
  }

  .navLinks {
    display: block;
    text-align: center;
    padding: 10px;
    background-color: #333; /* Background color for the menu */
  }

  .navLink {
    padding: 1rem;
    display: block;
    color: white;
    text-decoration: none;
  }

  .phoneButton {
    display: block;
    margin: 10px auto;
  }
}

/* Ensure hamburger icon is hidden above 780px */
@media (min-width: 780px) {
  .hamburgerIcon {
    display: none;
  }
}


























@media (max-width: 990px) {
  /* Hide on screens smaller than 768px (phones and small tablets) */


 .infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  border-bottom: 2px solid transparent;
  padding-bottom: 0; /* Remove padding at the bottom to bring the content close to the border */
  }
}

